import { Component, OnInit } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../../format-datepicker";
import * as moment from "moment";
//import { MAT_DATE_LOCALE, SatDatepickerModule } from "saturn-datepicker";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { DataService } from "../../../services/data.service";
import { AbstractControl, UntypedFormBuilder, FormGroup, ValidatorFn, Validators, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppCustomDirective } from "./app.validators";
import { AppointmentsService } from "../../../services/appointments.service";
import { throwError } from "rxjs";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import { TextService } from "../../../services/text.service";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { NoContentComponent } from "../../common/no-content/no-content.component";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { AppointmentsComponentComponent } from "../../generic-components/appointments-component/appointments-component.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { HeaderComponent } from "../../common/header/header.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { WorkTerminationPopupsComponent } from "../../generic-components/work-termination-popups/work-termination-popups.component";
declare var $: any;

@Component({
    selector: "app-appointments",
    templateUrl: "./appointments.component.html",
    styleUrls: ["./appointments.component.scss"],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    standalone: true,
    imports: [BreadcrumbsComponent, HeaderComponent, NgIf, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, NgClass, AppointmentsComponentComponent, MatLegacyPaginatorModule, WorkTerminationPopupsComponent, NoContentComponent]
})
export class AppointmentsComponent implements OnInit {
  name;
  selected;
  optionForFilter = ["שנה נוכחית", "רבעון אחרון", "חודש נוכחי", "מותאם אישית"];
  paginatorLength;
  data;
  currentItemsToShow = ["", "", "", ""];
  total_count;
  total_held;
  total_scheduled;
  showGhostElementsForTopPage = false;
  datePicker: any;
  dateInput = { from: undefined, to: undefined };
  breadcrumbsPages = [BreadcrumbsLinkNames.HOME, BreadcrumbsLinkNames.MEETINGS];

  constructor(
    public newAppointmentService: NewAppointmentService,
    private dateAdapter: DateAdapter<any>,
    public dataService: DataService,
    private fb: UntypedFormBuilder,
    public textService: TextService,
    public apoointmentservice: AppointmentsService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.name = this.dataService.name;
    this.dataService.showGhostElements = true;
    this.showGhostElementsForTopPage = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.apoointmentservice
      .getAllAppointmentsTypesByFilter("", "", "")
      .subscribe(
        (resp) => {
          this.dataService.showGhostElements = false;
          this.showGhostElementsForTopPage = false;
          this.dataService.showDataLoader = false;
          this.data = resp["meetings"];
          this.paginatorLength = this.data.length;
          this.optionForFilter = resp["filter"];
          this.selected = this.optionForFilter[0];
          this.currentItemsToShow = this.data.slice(0, 10);

          this.total_count = this.dataService.getResourceText(
            this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_SUBTITLE_1"],
            resp
          );
          this.total_held = this.dataService.getResourceText(
            this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_SUBTITLE_2"],
            resp
          );
          this.total_scheduled = this.dataService.getResourceText(
            this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_SUBTITLE_3"],
            resp
          );

          this.dateInput.from = moment(this.selected["date_range"]["from"]);
          this.dateInput.to = moment(this.selected["date_range"]["to"]);
          $(".date-input").val(
            this.dateInput.to.format("DD/MM/YY") +
              " - " +
              this.dateInput.from.format("DD/MM/YY")
          );
          $(".date-input").prop("disabled", true);
          this.saveInSoterage();
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
  }
  onPageChange($event) {
    this.currentItemsToShow = this.data.slice(
      $event.pageIndex * $event.pageSize,
      $event.pageIndex * $event.pageSize + $event.pageSize
    );
  }

  clear() {
    this.dateInput.from = "";
    this.dateInput.to = "";
    $(".date-input").val("");
    this.selected = this.optionForFilter[0];

    this.filterAppointments();
  }

  saveInSoterage() {
    sessionStorage.setItem("appointment-filter", this.selected["value"]);

    sessionStorage.setItem(
      "appointment-from-date",
      moment(this.dateInput.from).format("DD/MM/YY").toString()
    );
    sessionStorage.setItem(
      "appointment-to-date",
      moment(this.dateInput.to).format("DD/MM/YY").toString()
    );
  }

  filterAppointments() {
    this.selected;

    this.dateInput;
    if (this.selected["value"] != "") {
      $(".date-input").prop("disabled", true);
      this.dateInput.from = moment(this.selected["date_range"]["from"]);
      this.dateInput.to = moment(this.selected["date_range"]["to"]);
      $(".date-input").val(
        this.dateInput.to.format("DD/MM/YY") +
          " - " +
          this.dateInput.from.format("DD/MM/YY")
      );
    } else {
      $(".date-input").prop("disabled", false);
    }
    this.currentItemsToShow = ["", "", "", ""];
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.apoointmentservice
      .getAllAppointmentsTypesByFilter(
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        this.selected["value"]
      )
      .subscribe(
        (resp) => {
          this.dataService.showGhostElements = false;
          this.dataService.showDataLoader = false;
          this.total_count = this.dataService.getResourceText(
            this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_SUBTITLE_1"],
            resp
          );
          this.total_held = this.dataService.getResourceText(
            this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_SUBTITLE_2"],
            resp
          );
          this.total_scheduled = this.dataService.getResourceText(
            this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_SUBTITLE_3"],
            resp
          );

          this.data = resp["meetings"];
          this.paginatorLength = this.data.length;
          this.currentItemsToShow = this.data.slice(0, 10);
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
  }

  ngAfterContentInit(): void {
    this.datePicker = $(".date-input").daterangepicker(
      {
        autoApply: true,
        autoUpdateInput: false,
        opens: "center",
        locale: {
          format: "DD/MM/YYYY",
          separator: " - ",
          applyLabel: "אישור",
          cancelLabel: "ביטול",
          fromLabel: "מתאריך",
          toLabel: "עד תאריך",
          daysOfWeek: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
          monthNames: [
            "ינואר",
            "פברואר",
            "מרץ",
            "אפריל",
            "מאי",
            "יוני",
            "יולי",
            "אוגוסט",
            "ספטמבר",
            "אוקטובר",
            "נובמבר",
            "דצמבר",
          ],
          firstDay: 0,
        },
      },
      (start, end, label) => {
        this.dateInput.from = start;
        this.dateInput.to = end;
        $(".date-input").val(
          end.format("DD/MM/YY") + " - " + start.format("DD/MM/YY")
        );
        this.filterAppointments();
        this.saveInSoterage();
      }
    );
    $(".date-input").val("");
  }

  focusOnNewAppointmentPopup() {
    setTimeout(() => {
      $(".close").focus();
    }, 100);
  }
}
