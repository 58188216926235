import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";
import * as moment from "moment";
import { IPartTimeSalaryReductionPeriod, IPensionBeforeRetirementItem, IProvidentMoneyItem, IRetirementGrantPaymentRaterItem, IWorkTerminationRequestBody } from "../models/work-termination.model";
@Injectable({
  providedIn: "root",
})
export class NewAppointmentService extends HttpHandler {
  newAppointmentForSpecificEmployee = {};
  isItAppointmentForSpecificEmployee;
  dataForWorkTermination;
  isExcelUpload = false;
  isSingleWorkTermination = false;

  SR_response = {};
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  getDropdownOption() {
    const url = "/contact/service-request/meeting-type/create/filter";
    return this.get(url);
  }

  getEmployee(searchTerm, skip, limit) {
    const url = "/contact/employees";
    let params = new HttpParams({
      fromObject: {
        searchTerm: `${searchTerm}`,
        actives: "true",
      },
    });
    if (skip != "") {
      params = params.append("skip", `${skip}`);
    }
    if (limit != "") {
      params = params.append("limit", `${skip}`);
    }
    return this.get(url, params);
  }
  createNewAppointment(body) {
    const url = "/contact/service-request/meeting-type/create";

    return this.post(url, body);
  }
  generateWorkTerminationRequestBody(data, removeEmptyFields = true): IWorkTerminationRequestBody {
    const formatDateString = (date) => date ? moment(date).format("YYYY-MM-DD").toString() : undefined;
    const body: IWorkTerminationRequestBody = {
      workTerminationReason: data.workTerminationReason,
      retirementDate: formatDateString(data.retirementDate),
      startWorkDate: formatDateString(data.startWorkDate),
      lastMonthlySalary: data.lastMonthlySalary,
      lastInsuredsalary: data.lastInsuredsalary,
      section14: data.section14,
      compensationRelease: data.compensationRelease,
      salaryCompensationLiability:
        data.salaryCompensationLiability,
      completionLimit: data.completionLimit,
      lastPremiumDate: formatDateString(data.lastPremiumDate),
      haschomLeyomHaprish: data.haschomLeyomHaprish,
      taarichHafkadaRishon: formatDateString(
        data.taarichHafkadaRishon ),
      entitlementBudgetPension:
        data.entitlementBudgetPension,
      controllingOwner: data.controllingOwner,
      relationshipControllingOwner:
        data.relationshipControllingOwner,
      pemsionSequence: data.pemsionSequence,
      grantAmout: data.grantAmout,
      pensionSequenceAmout: data.pensionSequenceAmout,
      completionSequenceAmout:
        data.completionSequenceAmout,
      grantAmoutRelease: data.grantAmoutRelease,
      grantAmoutForces: data.grantAmoutForces,
      govaHamass: data.govaHamass,
      lastPremia: data.lastPremia,
      authorizedSignatory161: data.authorizedSignatory161,
      authorizedViewing161: data.authorizedViewing161,
      foreignLanguage: data.foreignLanguage,
      zakaaotSalary: data.zakaaotSalary,
      deductionsPortfolio: data.deductionsPortfolio,
      comments: data.comments,
      employment_id: data.employment_id,
      employerNumber: data.employerNumber,
      procedureId: data.procedureId,
      zakaaotDate: formatDateString(
        data.zakaaotDate
      ),
      partTimeSalaryReductionPeriods:
        data.partTimeSalaryReductionPeriods?.map(
          (item: IPartTimeSalaryReductionPeriod) => {
            return item ? {
              partTimeSalaryReductionStartDate: formatDateString(
                item.partTimeSalaryReductionStartDate
              ) ?? null
              ,
              partTimeSalaryReductionEndDate: formatDateString(
                item.partTimeSalaryReductionEndDate
              ) ?? null
              ,
              lastSalaryForPeriod: item.lastSalaryForPeriod,
              jobRatePercent: item.jobRatePercent,
            } as IPartTimeSalaryReductionPeriod : null;
          }
        ),
      providentMoneyArray: data.providentMoneyArray?.map(
        (item: IProvidentMoneyItem) => {
          return item ? {
            payerName: item.payerName,
            deductionsPortfolio: item.deductionsPortfolio,
            texedPayments: item.texedPayments,
          } as IProvidentMoneyItem : null;
        }
      ),
      retirementGrantPaymentRaterArray:
        data.retirementGrantPaymentRaterArray?.map(
          (item: IRetirementGrantPaymentRaterItem) => {
            return item ? {
              paymentDate: formatDateString(item.paymentDate)??null
              ,
              paymentSum: item.paymentSum,
            } as IRetirementGrantPaymentRaterItem : null;
          }
        ),
      pensionBeforeRetirementArray:
        data.pensionBeforeRetirementArray?.map(
          (item: IPensionBeforeRetirementItem) => {
            return item
              ? ({
                payerName60: item.payerName60,
                deductionsPortfolio60: item.deductionsPortfolio60,
                payerId: item.payerId,
                conversionMonth: item.conversionMonth,
                pensionAmout: item.pensionAmout,
                totalAmout: item.totalAmout,
              } as IPensionBeforeRetirementItem)
              : null;
          }
        ),
      personal_details: {
        city: data.city,
        street: data.street,
        bulding_number: data.buldingNumber,
        apartment_number: data.apartmentNumber,
        postal_code: data.postalCode,
        date_of_birth: formatDateString(data.birthDate),
        personal_email: data.personalEmail,
        mobile_phone: data.mobilePhone

      }
    };
    if (removeEmptyFields) {
      // remove empty/invalid keys
      for (const key in body) {
        if (!body[key] || body[key] === "Invalid date") {
          delete body[key];
        }
        if (Array.isArray(body[key])) {
          const array = body[key];
          array.forEach((item, index) => {
            for (const key in item) {
              if (!item[key] || item[key] === "Invalid date") {
                delete item[key];
              }
            }
            if (item == null || Object.keys(item).length === 0) {
              array.splice(index, 1);
            }
            if (array[0] === null) {
              body[key] = [];
            }
          });
        }
        else if (typeof (body[key]) == 'object') {
          for (const keyInObj in body[key]) {
            if (!body[key][keyInObj] || body[key][keyInObj] == "Invalid date") {
              delete body[key][keyInObj];
            }
          }
        }
      }
    }
    return body;
  }
}
