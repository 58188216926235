import { ChangeDetectionStrategy, Component, OnInit, ViewChild, signal } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import { RegisterPageService } from "src/app/services/register-page.service";
import { PopupTypes } from "../../../models/popuptype";
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";
import { Observable, combineLatest, of } from "rxjs";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { APP_DATE_FORMATS } from "../../../format-datepicker";
import { DateFormat } from "src/app/services/date-format.service";
import * as moment from "moment";
import { TextService } from "../../../services/text.service";
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger, MatLegacyAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { AppointmentTypesEnum } from "src/app/models/appointments.model";
import { EmployeeService } from "src/app/services/employee.service";
import { FileAttachmentService } from "src/app/services/file-attachment.service";
import {
  IFileAttachmentError,
  IFileAttachmentResponse,
} from "src/app/models/file-attachment.model";
import {
  PemsionSequenceEnum,
  WorkTerminationDataEnum,
  WorkTerminationReasonsEnum,
} from "src/app/models/work-termination.model";
import { ISelectOption } from "src/app/models/import-new-employees.model";
import { MaximumAmountsRetirement } from "./maximum-amounts-retirement/maximum-amounts-retirement.component";
import { RadioButtonComponent } from "../../common/radio-button/radio-button.component";
import { PensionBeforeRetirementArrayComponent } from "./pension-before-retirement-array/pension-before-retirement-array.component";
import { RetirementGrantPaymentRaterArrayComponent } from "./retirement-grant-payment-rater-array/retirement-grant-payment-rater-array.component";
import { ProvidentMoneyArrayComponent } from "./provident-money-array/provident-money-array.component";
import { WorkTerminationPeriodArrayComponentComponent } from "./work-termination-period-array-component/work-termination-period-array-component.component";
import { WorkTerminationComponent } from "./work-termination/work-termination.component";
import { UploadFilesComponent } from "../../generic-components/upload-files/upload-files.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { CommonModule } from "@angular/common";
import { FileUploadService } from "src/app/services/file-upload.service";
import { isEqual } from 'lodash';

declare var $: any;

@Component({
  selector: "app-new-appointment-popup",
  templateUrl: "./new-appointment-popup.component.html",
  styleUrls: ["./new-appointment-popup.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: DateFormat },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, MatLegacyAutocompleteModule, MatLegacyOptionModule, MatLegacySelectModule, MatDatepickerModule, UploadFilesComponent, WorkTerminationComponent, WorkTerminationPeriodArrayComponentComponent, ProvidentMoneyArrayComponent, RetirementGrantPaymentRaterArrayComponent, PensionBeforeRetirementArrayComponent, RadioButtonComponent, MaximumAmountsRetirement]
})
export class NewAppointmentPopupComponent implements OnInit {
  constructor(
    public fb: UntypedFormBuilder,
    private dataService: DataService,
    private newAppointmentService: NewAppointmentService,
    private registerPageService: RegisterPageService,
    public textService: TextService,
    private employeeService: EmployeeService,
    private fileAttachmentService: FileAttachmentService,
    private fileUploadService: FileUploadService,
  ) {
    this.textService.setTextSource();
  }

  @ViewChild("autoCompleteInput", { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  sizeDataArray;
  originalForm;
  changedFields;
  appointmentForm: UntypedFormGroup;
  types;
  workerDetails: Observable<any>;
  pemsionSequenceEnum = PemsionSequenceEnum;
  workTerminationDataEnum = WorkTerminationDataEnum;
  workTerminationReasonsEnum = WorkTerminationReasonsEnum;
  data: any[] = [];
  dataForWorkTermination;
  isExcelUpload = false;
  chosenFund = false;
  chosenUploadSalary = false;
  chosenEmployee;
  type;
  isAboveSixty = false;
  SR;
  placeholderTextArea;
  error_message = signal("");
  name;
  selectionExist = true;
  appointmentTypesEnum = AppointmentTypesEnum;
  isItAppointmentForSpecificEmployee;
  newAppointmentForSpecificEmployee;
  validation_messages = {
    employer_compensation: [{ type: "required", message: "שדה חובה" }],
    employee_compensation: [{ type: "required", message: "שדה חובה" }],
    is_up_to_the_ceiling_of_the_training_fund: [
      { type: "required", message: "שדה חובה" },
    ],
    date_training_fund: [
      { type: "required", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
    ],
    retirementDate: [
      { type: "required", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
    ],
    workTerminationReason: [{ type: "required", message: "שדה חובה" }],
    eligibility_date_training_fund: [{ type: "required", message: "שדה חובה" }],
    employeeSalary: [
      { type: "required", message: "שדה חובה" },
      { type: "pattern", message: "יש להזין מספרים בלבד" },
      { type: "maxlength", message: "יש להזין לכל היותר 6 תווים" },
    ],
    upload_date_salary: [
      { type: "required", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
    ],
    input_for_other_study_fund_deposit: [
      { type: "required", message: "שדה חובה" },
      { type: "pattern", message: "יש להזין מספרים בלבד" },
      { type: "maxlength", message: "יש להזין לכל היותר 6 תווים" },
    ],
  };
  study_fund_deposit;
  eligibility_date_study_fund;
  study_fund_employee_deposit;
  study_fund_employer_deposit;
  work_termination_reason = [];
  controlling_owner = [];
  foreign_language = [];
  employees_to_sign = [];
  employees_to_view = [];
  errorInIdNumber = false;
  showFundDate = false;
  showInputDeposit = false;
  sendWorkTermination = false;
  employeeData = this.employeeService.employeeData;
  pemsionSequenceOptions: ISelectOption[] = [
    {
      label: this.textService.textSource.PENSION_SEQUENCE_OPTION2,
      value: this.textService.textSource.PENSION_SEQUENCE_OPTION2,
    },
    {
      label: "אינו עולה",
      value: this.textService.textSource.PENSION_SEQUENCE_OPTION1,
    },
  ];

  checkAge(): void {
    const birthdateEmployee =
      this.employeeService.employeeData?.personal_details.birthdate;
    if (birthdateEmployee) {
      const currentDate = new Date();
      const diffInMilliseconds =
        currentDate.getTime() - new Date(birthdateEmployee).getTime();
      const ageInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
      this.isAboveSixty = ageInYears >= 60;
    } else {
      this.isAboveSixty = true;
    }
  }
  ngOnInit(): void {
    this.checkAge();
    this.isItAppointmentForSpecificEmployee =
      this.newAppointmentService.isItAppointmentForSpecificEmployee;
    this.newAppointmentForSpecificEmployee =
      this.newAppointmentService.newAppointmentForSpecificEmployee;
    window.addEventListener("scroll", this.scrollEvent, true);
    this.name = this.dataService.name;
    this.placeholderTextArea =
      this.textService.textSource["NEW_MEETING_SCREEN_TITLE_3_PLACEHOLDER"];
    this.dataService.showDataLoader = true;
    combineLatest([this.registerPageService.getFormOptions(), this.newAppointmentService.getDropdownOption()]).subscribe(
      ([formOptions, dropdownOptions]) => {
        this.dataService.showDataLoader = false;
        this.types = dropdownOptions;
        this.eligibility_date_study_fund = formOptions["eligibility_date_study_fund"];
        this.study_fund_employee_deposit = formOptions["study_fund_employee_deposit"];
        this.study_fund_employer_deposit = formOptions["study_fund_employer_deposit"];
        this.study_fund_deposit = formOptions["study_fund_deposit"];
        this.work_termination_reason = formOptions["work_termination_reason"];
        this.employees_to_sign = formOptions["employees_to_sign"];
        this.employees_to_view = formOptions["employees_to_view"];
        this.controlling_owner = formOptions["controlling_owner"];
        this.foreign_language = formOptions["foreign_language"];
        if (!this.dataForWorkTermination) {
          this.appointmentForm.patchValue({ "workTerminationReason": formOptions["work_termination_reason"][0].value, [this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO]: formOptions["deductions_portfolio"] });
          if (formOptions["employees_to_sign"].length > 0) {
            this.appointmentForm.controls[this.workTerminationDataEnum.AUTHORIZED_TO_SIGN_FORM].setValue(formOptions["employees_to_sign"][0].id);
          }
        }
        else {
          this.appointmentForm.controls.appointment_type.setValue(this.types[0]);
          this.type = this.types[0];
        }
        if (formOptions["employees_to_view"].length > 0 && !this.dataForWorkTermination?.authorizedViewing161) {
          this.appointmentForm.controls[this.workTerminationDataEnum.AUTHORIZED_TO_VIEW_FORM].setValue(formOptions["employees_to_view"][0].id);
        }
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );

    this.appointmentForm = this.fb.group({
      search_text: new UntypedFormControl("", [
        Validators.compose([Validators.required, this.forbiddenNamesValidator]),
      ]),
      appointment_type: new UntypedFormControl(null, Validators.required),
      notes: new UntypedFormControl("", []),
      is_up_to_the_ceiling_of_the_training_fund: new UntypedFormControl("", []),
      employer_compensation: new UntypedFormControl("", []),
      employee_compensation: new UntypedFormControl("", []),
      eligibility_date_training_fund: new UntypedFormControl("", []),
      date_training_fund: new UntypedFormControl("", []),
      employeeSalary: new UntypedFormControl("", []),
      upload_date_salary: new UntypedFormControl("", []),
      input_for_other_study_fund_deposit: new UntypedFormControl("", []),

      // work-termination fields
      retirementDate: new UntypedFormControl("", [Validators.required]),
      workTerminationReason: new UntypedFormControl("", [Validators.required]),
      [this.workTerminationDataEnum.ZAKAAOT_SALARY]: new UntypedFormControl("", []),
      [this.workTerminationDataEnum.ZAKAAOT_DATE]: new UntypedFormControl("", []),
      [this.workTerminationDataEnum.MOBILE_PHONE]: new UntypedFormControl(this.employeeData?.personal_details.mobile_phone),
      [this.workTerminationDataEnum.PERSONAL_EMAIL]: new UntypedFormControl(this.employeeData?.personal_details.personal_email),
      [this.workTerminationDataEnum.CITY]: new UntypedFormControl(this.employeeData?.personal_details.city),
      [this.workTerminationDataEnum.STREET]: new UntypedFormControl(this.employeeData?.personal_details.street),
      [this.workTerminationDataEnum.BUILDING_NUMBER]: new UntypedFormControl(this.employeeData?.personal_details.bulding_number),
      [this.workTerminationDataEnum.NUM_HOUSE]: new UntypedFormControl(this.employeeData?.personal_details.apartment_number),
      [this.workTerminationDataEnum.POSTAL_CODE]: new UntypedFormControl(this.employeeData?.personal_details.postal_code),
      [this.workTerminationDataEnum.BIRTHDATE]: new UntypedFormControl(this.employeeData?.personal_details.birthdate),
      [this.workTerminationDataEnum.FOREIGN_LANGUAGE]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.WORKER_NUMBER]: new UntypedFormControl(this.isExcelUpload ? this.employeeData?.work_details?.employer_number : ""),
      [this.workTerminationDataEnum.COMMENTS]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO]: new UntypedFormControl(),
      [this.workTerminationDataEnum.AUTHORIZED_TO_SIGN_FORM]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.AUTHORIZED_TO_VIEW_FORM]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.DEDUCTIBLE_TAX_RATED]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.LAST_PREMIUM]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.START_WORK_DATE]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.LAST_MONTHLY_SALARY]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.LAST_INSURED_SALARY]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.SECTION14]: new UntypedFormControl(this.employeeData?.social_allowances?.paragraph_14),
      [this.workTerminationDataEnum.COMPENSATION_RELEASE]: new UntypedFormControl(true),
      [this.workTerminationDataEnum.COMPLETION_AMOUNT]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.DATE_PAYMENT_COMPENSATION_COMPLETION]:
        new UntypedFormControl(""),
      [this.workTerminationDataEnum.ENTITLEMEMT_BUDGET_PENSION]:
        new UntypedFormControl(false),
      [this.workTerminationDataEnum.CONTROLLING_OWNER]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.RELATIONSHIP_CONTROLLING_OWNER]:
        new UntypedFormControl(""),
      [this.workTerminationDataEnum.LAST_PREMIUM_DATE]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.SALARY_COMPENSATION_LIABILITY]:
        new UntypedFormControl(""),
      partTimeSalaryReductionPeriods: new UntypedFormControl([]),
      providentMoneyArray: new UntypedFormControl([]),
      retirementGrantPaymentRaterArray: new UntypedFormControl([]),
      pensionBeforeRetirementArray: new UntypedFormControl([]),
      [this.workTerminationDataEnum.PENSION_SEQUENCE_OPTION]: new UntypedFormControl(
        ""
      ),
      [this.workTerminationDataEnum.GRANT_AMOUNT]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.PENSION_SEQUENCE_AMOUNT]: new UntypedFormControl(
        ""
      ),
      [this.workTerminationDataEnum.COMPLETION_SEQUENCE_AMOUNT]:
        new UntypedFormControl(""),
      [this.workTerminationDataEnum.GRANT_AMOUNT_RELEASE]: new UntypedFormControl(""),
      [this.workTerminationDataEnum.GRANT_AMOUNT_FORCED]: new UntypedFormControl(""),
    });

    if (this.newAppointmentService.dataForWorkTermination) {
      this.isExcelUpload = this.newAppointmentService.isExcelUpload;
      this.dataForWorkTermination = this.newAppointmentService.dataForWorkTermination;
      this.newAppointmentService.dataForWorkTermination = null;
      this.initializeDataForWorkTermination();
      this.appointmentForm.patchValue(this.dataForWorkTermination);
      if (!this.isExcelUpload) {//in update
        if (!this.dataForWorkTermination.route) {
          this.originalForm = this.appointmentForm.value;
        }
        else {
          this.appointmentForm.disable();
        }
      }
      this.appointmentForm.get('appointment_type').disable();
    }

    if (this.isItAppointmentForSpecificEmployee) {
      this.appointmentForm.get("search_text").disable();
      this.chosenEmployee = this.newAppointmentForSpecificEmployee;
    }
    else {
      this.appointmentForm.get('appointment_type').disable();
    }
  }

  getSearchResult(event) {
    if (event.keyCode == 38 || event.keyCode == 40) {
      return;
    }
    let searchWord = event.target.value;
    let searchWordLength = searchWord.length;
    if (searchWordLength > 2) {
      this.dataService.showDataLoader = true;
      this.newAppointmentService.getEmployee(searchWord, "", "").subscribe(
        (resp) => {
          this.dataService.showDataLoader = false;
          this.workerDetails = of(resp["employees"]);
          this.data = resp["employees"];

          if (resp["employees"].length === 0) {
            this.selectionExist = false;
          } else {
            this.selectionExist = true;
          }
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
    }
    if (searchWordLength == 0) {
      this.workerDetails = null;
    }
  }

  send() {
    if (this.type["value"] === this.appointmentTypesEnum.WORK_TERMINATION) {
      this.sendWorkDepartureData();
      return;
    }
    this.dataService.showDataLoader = true;
    let body = {
      meeting_type: this.appointmentForm.value.appointment_type["value"],
      employment_id: this.chosenEmployee["employment_id"],
    };
    if (this.appointmentForm.value.notes != "") {
      body["description"] = this.appointmentForm.value.notes;
    }
    if (
      this.appointmentForm.value.appointment_type["value"] === "קרן השתלמות"
    ) {
      if (
        this.appointmentForm.value.eligibility_date_training_fund["label"] ===
        "אחר"
      ) {
        this.appointmentForm.value.eligibility_date_training_fund["value"] =
          moment(this.appointmentForm.value.date_training_fund)
            .format("YYYY-MM-DDThh:mm:ssZ")
            .toString();
      }
      body["fund_study"] = {
        eligibility_date_study_fund:
          this.appointmentForm.value.eligibility_date_training_fund["value"],
        study_fund_employer_deposit:
          this.appointmentForm.value.employer_compensation["value"],
        study_fund_employee_deposit:
          this.appointmentForm.value.employee_compensation["value"],
        study_fund_deposit:
          this.appointmentForm.value
            .is_up_to_the_ceiling_of_the_training_fund["value"],
      };

      if (
        this.appointmentForm.value.eligibility_date_training_fund["label"] ===
        "אחר"
      ) {
        body["fund_study"]["other_study_fund_deposit"] =
          this.appointmentForm.value.input_for_other_study_fund_deposit;
      }
    }
    if (this.appointmentForm.value.appointment_type["value"] === "NONE") {
      body["salary"] = {
        new_salary: this.appointmentForm.value.employeeSalary,
        change_start_date: moment(
          this.appointmentForm.value.upload_date_salary
        )
          .format("YYYY-MM-DD")
          .toString(),
      };
    }

    this.newAppointmentService.createNewAppointment(body).subscribe(
      (resp) => {
        this.dataService.showDataLoader = false;
        this.newAppointmentService.SR_response = resp;
        this.dataService.showNewAppointmentPopup = false;
        this.dataService.showApprovedRequestPopup = true;
        setTimeout(() => {
          $(".thankyou").focus();
        }, 100);
      },
      (err) => {
        this.dataService.handleErrors(err);
        this.error_message.set(err["message"]);
      }
    );
  }

  public forbiddenNamesValidator(control: UntypedFormControl) {
    let searchWord = String(control.value).trim();

    if (searchWord.length < 9) {
      return { pattern: true };
    }
  }

  resetFormGroupValidators(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.controls[controlName];
      control.clearValidators();
      control.updateValueAndValidity();
    });
  }

  setControlValidators(controlNames: string[]) {
    controlNames.forEach((control) => {
      const validators = this.getControlValidators(control);
      this.appointmentForm.controls[control].setValidators(validators);
      this.appointmentForm.controls[control].updateValueAndValidity();
    });
  }

  getControlValidators(controlName) {
    return (
      {
        ["retirementDate"]: [Validators.required, this.validateDate],
        ["workTerminationReason"]: [Validators.required],
        ["is_up_to_the_ceiling_of_the_training_fund"]: [Validators.required],
        ["employer_compensation"]: [Validators.required],
        ["employee_compensation"]: [Validators.required],
        ["eligibility_date_training_fund"]: [Validators.required],
        ["employeeSalary"]: [
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/i),
        ],
        ["upload_date_salary"]: [Validators.required],
        [this.workTerminationDataEnum.START_WORK_DATE]: [Validators.required, this.validateDate],
        [this.workTerminationDataEnum.LAST_MONTHLY_SALARY]: [
          Validators.required,
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.SECTION14]: [],
        [this.workTerminationDataEnum.COMPENSATION_RELEASE]: [],
        [this.workTerminationDataEnum.LAST_PREMIUM_DATE]: [this.validateDate],
        [this.workTerminationDataEnum.SALARY_COMPENSATION_LIABILITY]: [
          Validators.required,
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.COMPLETION_AMOUNT]: [
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.DATE_PAYMENT_COMPENSATION_COMPLETION]: [
          this.validateDate
        ],
        [this.workTerminationDataEnum.ENTITLEMEMT_BUDGET_PENSION]: [],
        [this.workTerminationDataEnum.CONTROLLING_OWNER]: [],
        [this.workTerminationDataEnum.RELATIONSHIP_CONTROLLING_OWNER]: [],
        [this.workTerminationDataEnum.PART_TIME_SALARY_REDUCTION_START_DATE]: [
          this.validateDate
        ],
        [this.workTerminationDataEnum.PART_TIME_SALARY_REDUCTION_END_DATE]: [
          this.validateDate
        ],
        [this.workTerminationDataEnum.LAST_SALARY_PERIOD]: [
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/i),
        ],
        [this.workTerminationDataEnum.LAST_INSURED_SALARY]: [
          Validators.required,
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.JOB_RATE_PERCENT]: [
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/i),
        ],
        [this.workTerminationDataEnum.PART_TIME_SALARY_REDUCTION_PERIODS]: [
          Validators.maxLength(6),
        ],
        [this.workTerminationDataEnum.NUM_HOUSE]: [
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.BUILDING_NUMBER]: [
          Validators.pattern(/^[0-9./]+$/i),
        ],
        [this.workTerminationDataEnum.POSTAL_CODE]: [
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.DEDUCTIBLE_TAX_RATED]: [
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.LAST_PREMIUM]: [
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.PERSONAL_EMAIL]: [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i),
        ],
        [this.workTerminationDataEnum.CITY]: [Validators.required],
        [this.workTerminationDataEnum.MOBILE_PHONE]: [
          Validators.required,
          Validators.pattern(/^\d{9,10}$/),
        ],
        [this.workTerminationDataEnum.ZAKAAOT_DATE]: [
          this.validateDate
        ],
        [this.workTerminationDataEnum.ZAKAAOT_SALARY]: [
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO]: [
          Validators.required,
          Validators.pattern(/^[0-9.]+$/i),
        ],
        [this.workTerminationDataEnum.BIRTHDATE]: [
          this.validateDate
        ],
        [this.workTerminationDataEnum.AUTHORIZED_TO_SIGN_FORM]: [
          Validators.required
        ]
      }[controlName] ?? []
    );
  }

  async sendWorkDepartureData(data = this.appointmentForm.value) {
    try {
      this.sendWorkTermination = true;
      this.dataService.showDataLoader = true;
      const response = await this.openWorkDepartureProcess(data);
      if (response && response["id"]) {
        const filesAttachmentData: IFileAttachmentResponse =
          await this.fileAttachmentService
            .sendFilesAttachment(response["id"])
            .toPromise();
        if (!filesAttachmentData.success) {
          this.dataService.showDataLoader = false;
          const errors = filesAttachmentData.errors;
          const filesUploadErrors = [];
          errors.forEach((error: IFileAttachmentError) => {
            filesUploadErrors.push(error.fileName);
          });
          this.fileAttachmentService.addFilesUploadErrors(filesUploadErrors);
        }
        this.dataService.showNewAppointmentPopup = false;
        this.dataService.showApprovedRequestPopup = true;
      } else {
        console.log("not");
      }
    } catch (error) {
      this.dataService.showDataLoader = false;
      this.dataService.handleErrors(error);
      this.error_message.set(this.textService.textSource["ERROR_GENERAL"]+' '+ this.textService.textSource["GUR_PHONE_NUMBER"]);
    }
  }

  validateDate(control: UntypedFormControl) {
    if (control.value) {
      const date = moment(control.value);
      const isValid = date.isValid();
      if (!isValid) {
        return { date: true };
      }
    }
    return null;
  }

  openWorkDepartureProcess(data) {
    return new Promise((resolve, reject) => {
      this.dataService.showDataLoader = true;
      const { employment_id } = this.employeeService.employeeData;
      const body = this.newAppointmentService.generateWorkTerminationRequestBody(data, !this.changedFields ? true : false);
      this.employeeService
        .postEmployeeWorkTermination(employment_id, body)
        .subscribe(
          (resp) => {
            this.employeeService.employeeData.personal_details = {
              ...this.employeeData.personal_details, ...body.personal_details, birthdate: body.personal_details.date_of_birth
            };
            this.employeeService.employeeDataArrived.next();
            this.dataService.showDataLoader = false;
            this.newAppointmentService.SR_response = resp;
            this.dataService.showNewAppointmentPopup = false;
            this.dataService.showApprovedRequestPopup = true;
            resolve(resp);
          },
          (err) => {
            this.dataService.showDataLoader = false;
            this.dataService.handleErrors(err);
            this.error_message.set(err["message"]);
            reject(err);
          }
        );
    });
  }

  showFields(type) {
    this.type = type["value"];
    type = type["value"];
    this.chosenFund = false;
    this.chosenUploadSalary = false;
    const searchText = this.appointmentForm.value.search_text;

    // reset all form fields but preserve needed fields. (EXAMPLE search text & type)
    this.resetFormGroupValidators(this.appointmentForm);
    this.appointmentForm.controls["appointment_type"].setValue(type);
    this.appointmentForm.controls["search_text"].setValue(searchText);

    this.appointmentForm.patchValue({
      is_up_to_the_ceiling_of_the_training_fund: "",
      employer_compensation: "",
      employee_compensation: "",
      eligibility_date_training_fund: "",
      input_for_other_study_fund_deposit: "",
      date_training_fund: "",
      upload_date_salary: "",
      employeeSalary: "",
      retirementDate: "",
      workTerminationReason: this.work_termination_reason[0].value,
      partTimeSalaryReductionPeriods: [
        {
          [this.workTerminationDataEnum.PART_TIME_SALARY_REDUCTION_START_DATE]:
            "",
          [this.workTerminationDataEnum.PART_TIME_SALARY_REDUCTION_END_DATE]:
            "",
          [this.workTerminationDataEnum.JOB_RATE_PERCENT]: "",
          [this.workTerminationDataEnum.LAST_SALARY_PERIOD]: "",
        },
      ],
      providentMoneyArray: [
        {
          [this.workTerminationDataEnum.PAYER_NAME]: "",
          [this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO]: "",
          [this.workTerminationDataEnum.TAXED_PAYMENTS]: "",
        },
      ],
      retirementGrantPaymentRaterArray: [
        {
          [this.workTerminationDataEnum.PAYMENT_DATE]: "",
          [this.workTerminationDataEnum.PAYMENT_SUM]: "",
        },
      ],
      pensionBeforeRetirementArray: [
        {
          [this.workTerminationDataEnum.PAYER_NAME60]: "",
          [this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO60]: "",
          [this.workTerminationDataEnum.PAYER_ID]: "",
          [this.workTerminationDataEnum.CONVERSION_MONTH]: "",
          [this.workTerminationDataEnum.PENSION_AMOUNT]: "",
          [this.workTerminationDataEnum.TOTAL_AMOUNT]: "",
        },
      ],
    });

    if (type["label"] === "קרן השתלמות") {
      this.chosenFund = true;
      this.setControlValidators([
        "is_up_to_the_ceiling_of_the_training_fund",
        "employer_compensation",
        "employee_compensation",
        "eligibility_date_training_fund",
      ]);

      this.appointmentForm.patchValue({
        is_up_to_the_ceiling_of_the_training_fund: this.study_fund_deposit[0],
      });
    }
    if (type["label"] === "עדכון שכר") {
      this.chosenUploadSalary = true;
      this.setControlValidators(["employeeSalary", "upload_date_salary"]);
    }
    if (type["label"] === this.appointmentTypesEnum.WORK_TERMINATION) {
      this.employeeService.checkWorkTerminationExists(this.employeeService.employeeData.employment_id).subscribe(
        resp => {
          if (resp) {
            this.dataService.showNewAppointmentPopup = false;
            this.dataService.showIsExistsProcedurePopup = true;
          }
        }
      )

      const salary =
        this.employeeService.employeeData?.work_details?.salary || "";
      const start_work_date =
        this.employeeService.employeeData?.work_details?.start_work_date || "";
      this.appointmentForm.controls[
        this.workTerminationDataEnum.START_WORK_DATE
      ].setValue(start_work_date);
      this.setWorkTerminationValidators();
    }
  }
  setWorkTerminationValidators() {
    this.setControlValidators([
      "retirementDate",
      "workTerminationReason",
      this.workTerminationDataEnum.START_WORK_DATE,
      this.workTerminationDataEnum.LAST_MONTHLY_SALARY,
      this.workTerminationDataEnum.LAST_INSURED_SALARY,
      this.workTerminationDataEnum.SECTION14,
      this.workTerminationDataEnum.COMPENSATION_RELEASE,
      this.workTerminationDataEnum.LAST_PREMIUM_DATE,
      this.workTerminationDataEnum.SALARY_COMPENSATION_LIABILITY,
      this.workTerminationDataEnum.COMPLETION_AMOUNT,
      this.workTerminationDataEnum.RELATIONSHIP_CONTROLLING_OWNER,
      this.workTerminationDataEnum.ENTITLEMEMT_BUDGET_PENSION,
      this.workTerminationDataEnum.DATE_PAYMENT_COMPENSATION_COMPLETION,
      this.workTerminationDataEnum.CONTROLLING_OWNER,
      this.workTerminationDataEnum.NUM_HOUSE,
      this.workTerminationDataEnum.BUILDING_NUMBER,
      this.workTerminationDataEnum.POSTAL_CODE,
      this.workTerminationDataEnum.DEDUCTIBLE_TAX_RATED,
      this.workTerminationDataEnum.LAST_PREMIUM,
      this.workTerminationDataEnum.BIRTHDATE,
      this.workTerminationDataEnum.PERSONAL_EMAIL,
      this.workTerminationDataEnum.MOBILE_PHONE,
      this.workTerminationDataEnum.ZAKAAOT_DATE,
      this.workTerminationDataEnum.ZAKAAOT_SALARY,
      this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO,
      this.workTerminationDataEnum.CITY,
    ]);
  }
  dateFundChosenOther(status) {
    status = status["value"];
    if (status["label"] === "אחר") {
      this.showFundDate = true;
      this.appointmentForm.controls["date_training_fund"].setValidators([
        Validators.required,
      ]);
      this.appointmentForm.controls[
        "date_training_fund"
      ].updateValueAndValidity();
    } else {
      this.showFundDate = false;
      this.appointmentForm.controls["date_training_fund"].clearValidators();
      this.appointmentForm.controls[
        "date_training_fund"
      ].updateValueAndValidity();

      this.appointmentForm.patchValue({
        date_training_fund: "",
      });
    }
  }

  InputDepositChosenOther(status) {
    status = status["value"];
    if (status["label"] === "אחר") {
      this.showInputDeposit = true;
      this.appointmentForm.controls[
        "input_for_other_study_fund_deposit"
      ].setValidators([
        Validators.required,
        Validators.maxLength(6),
        Validators.pattern(/^[0-9]+$/i),
      ]);
      this.appointmentForm.controls[
        "input_for_other_study_fund_deposit"
      ].updateValueAndValidity();
    } else {
      this.showInputDeposit = false;
      this.appointmentForm.controls[
        "input_for_other_study_fund_deposit"
      ].clearValidators();
      this.appointmentForm.controls[
        "input_for_other_study_fund_deposit"
      ].updateValueAndValidity();

      this.appointmentForm.patchValue({
        input_for_other_study_fund_deposit: "",
      });
    }
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete) {
      if (this.autoComplete.panelOpen) {
        this.autoComplete.updatePosition();
      }
    }
  };

  focusOnCloseButton(event) { }

  chooseEmployee(worker) {
    this.chosenEmployee = worker;
    this.dataService.showDataLoader = true;
    this.employeeService.getEmployee(worker.employment_id).subscribe((resp) => {
      this.dataService.showDataLoader = false;
      this.employeeService.employeeData = resp;
      this.employeeData = resp;
      this.appointmentForm.patchValue({
        [WorkTerminationDataEnum.MOBILE_PHONE]: this.employeeData.personal_details.mobile_phone,
        [WorkTerminationDataEnum.PERSONAL_EMAIL]: this.employeeData.personal_details.personal_email,
        [WorkTerminationDataEnum.CITY]: this.employeeData.personal_details.city,
        [WorkTerminationDataEnum.STREET]: this.employeeData.personal_details.street,
        [WorkTerminationDataEnum.BUILDING_NUMBER]: this.employeeData.personal_details.bulding_number,
        [WorkTerminationDataEnum.NUM_HOUSE]: this.employeeData.personal_details.apartment_number,
        [WorkTerminationDataEnum.POSTAL_CODE]: this.employeeData.personal_details.postal_code,
        [WorkTerminationDataEnum.BIRTHDATE]: this.employeeData.personal_details.birthdate,
        [WorkTerminationDataEnum.SECTION14]: this.employeeData.social_allowances.paragraph_14,
      });
      const birthdateEmployee =
        this.employeeService.employeeData?.personal_details.birthdate;
      if (birthdateEmployee) {
        const currentDate = new Date();
        const diffInMilliseconds =
          currentDate.getTime() - new Date(birthdateEmployee).getTime();
        const ageInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation for leap years
        this.isAboveSixty = ageInYears >= 60;
      } else {
        this.isAboveSixty = true;
      }
      if (this.newAppointmentService.isSingleWorkTermination) {
        this.showFields({
          value: this.types[0]
        })
        this.appointmentForm.controls["appointment_type"].setValue(this.types[0]);
      }
      else {
        this.appointmentForm.get('appointment_type').enable();
      }
    }
    )
  }

  initializeDataForWorkTermination() {
    for (const key in this.dataForWorkTermination) {
      if (this.dataForWorkTermination[key] != null && typeof this.dataForWorkTermination[key] === 'object' && this.dataForWorkTermination[key].hasOwnProperty('msg')) {
        if (key == "id_number") {
          this.errorInIdNumber = this.dataForWorkTermination[key].msg;
          this.isAboveSixty = false;
        }
        else {
          this.dataForWorkTermination[key] = this.dataForWorkTermination[key].value;
        }
      }
      //delete empty values
      if (this.dataForWorkTermination[key] === "") {
        delete this.dataForWorkTermination[key];
      }
    }
    if (this.isAboveSixty && !this.dataForWorkTermination.pensionBeforeRetirementArray) {

      this.dataForWorkTermination.pensionBeforeRetirementArray = [
        {
          [this.workTerminationDataEnum.PAYER_NAME60]: "",
          [this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO60]: "",
          [this.workTerminationDataEnum.PAYER_ID]: "",
          [this.workTerminationDataEnum.CONVERSION_MONTH]: "",
          [this.workTerminationDataEnum.PENSION_AMOUNT]: "",
          [this.workTerminationDataEnum.TOTAL_AMOUNT]: "",
        },
      ]
    }
    this.setWorkTerminationValidators();
    this.setControlValidators(["authorizedSignatory161"]);
  }

  next() {
    if (this.isExcelUpload) {
      this.saveRecord();
    }
    else {
      if (this.dataForWorkTermination) {//update procedure
        for (const key in this.appointmentForm.value) {
          if (this.appointmentForm.value.hasOwnProperty(key) && this.originalForm.hasOwnProperty(key)) {
            if (!isEqual(this.appointmentForm.value[key], this.originalForm[key])) {
              this.changedFields = { ...this.changedFields, [key]: this.appointmentForm.value[key] }
            }
          }
        }
        this.changedFields = { ...this.changedFields, procedureId: this.dataForWorkTermination.id }
        this.sendWorkDepartureData(this.changedFields);
      }
      else {
        this.send()
      }
    }
  }

  saveRecord() {
    this.fileUploadService.patchRecordValue({ ...this.dataForWorkTermination, ...this.appointmentForm.value });
    this.dataService.showNewAppointmentPopup = false;
  }

}