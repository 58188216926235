import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RegisterUserDataEnum } from "src/app/models/register-user-data.model";
import { DataService } from "src/app/services/data.service";
import { ToasterService } from "src/app/services/toaster.service";
import { EmployeeDetailsModalComponent } from "../../generic-components/employee-details-modal/employee-details-modal.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { BidiModule } from "@angular/cdk/bidi";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { PopupTypes } from "src/app/models/popuptype";
import { EmployeeService } from "src/app/services/employee.service";
import { WorkTerminationDataEnum } from "src/app/models/work-termination.model";


@Component({
  selector: 'app-terminate-employees-work-table',
  standalone: true,
  imports: [ReactiveFormsModule, BidiModule, MatIconModule, CommonModule],
  templateUrl: './terminate-employees-work-table.component.html',
  styleUrls: ['./terminate-employees-work-table.component.scss']
})
export class TerminateEmployeesWorkTableComponent {
  @Input() data = [];

  tableColumns = [
    { value: RegisterUserDataEnum.FIRST_NAME, label: "שם פרטי" },
    { value: RegisterUserDataEnum.LAST_NAME, label: "שם משפחה" },
    { value: RegisterUserDataEnum.ID_NUMBER, label: "מספר זיהוי" },
    { value: "Actions", label: "פעולות" },
  ];

  constructor(
    public dataService: DataService,
    private toasterService: ToasterService,
    private newAppointmentService: NewAppointmentService, private employeeService: EmployeeService
  ) { }

  editRecord(i) {
    this.dataService.showNewAppointmentPopup = true;
    this.newAppointmentService.dataForWorkTermination = this.data[i];
    this.employeeService.employeeData = this.data[i].employeeData;
    this.newAppointmentService.newAppointmentForSpecificEmployee = {
      employment_id: this.data[i]["employment_id"],
      id_number: this.data[i]["id_number"].value ?? this.data[i]["id_number"],
      name: this.data[i]["first_name"] + ' ' + this.data[i]["last_name"],
    };
    this.newAppointmentService.isItAppointmentForSpecificEmployee = true;
    this.newAppointmentService.isExcelUpload = true;
  }

  deleteRecord(i) {
    this.data.splice(i, 1);
    this.toasterService.show("success", "success!", "רשומה נמחקה בהצלחה", 2000);
  }
}
