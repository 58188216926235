const MY_APP_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY', // Specify your desired date format here
  },
  display: {
    dateInput: 'MM/DD/YYYY', // Specify the format for displaying the date
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import { Component, OnInit } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../../format-datepicker";

import { DataService } from "src/app/services/data.service";
import { PopupTypes } from "../../../models/popuptype";
import * as moment from "moment";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { ActivatedRoute } from "@angular/router";
import { AppointmentsService } from "../../../services/appointments.service";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import { TextService } from "../../../services/text.service";
import { NoContentComponent } from "../../common/no-content/no-content.component";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { AppointmentTypeComponentComponent } from "../../generic-components/appointment-type-component/appointment-type-component.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { HeaderComponent } from "../../common/header/header.component";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { WorkTerminationPopupsComponent } from "../../generic-components/work-termination-popups/work-termination-popups.component";
declare var $: any;
@Component({
    selector: "app-appointment-type",
    templateUrl: "./appointment-type.component.html",
    styleUrls: ["./appointment-type.component.scss"],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_APP_DATE_FORMATS },
    ],
    standalone: true,
    imports: [NgIf, HeaderComponent, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, NgClass, AppointmentTypeComponentComponent, MatLegacyPaginatorModule, WorkTerminationPopupsComponent, NoContentComponent]
})
export class AppointmentTypeComponent implements OnInit {
  total_count;
  total_held;
  total_scheduled;
  maxDate = new Date();
  nameType;
  name;
  items: any[];
  pageWidth = window.innerWidth;
  selectedStauts;
  paginatorLength;
  currentItemsToShow = ["", "", "", "", "", ""];
  selected_time_label;
  optionsForPeriodFilter;
  optionForStatusFilter = ["התקיימו", "מתוכננות", "הכל"];
  datePicker: any;
  dateInput = { from: undefined, to: undefined };
  showGhostElementsForTopPage = false;
  constructor(
    public newAppointmentService: NewAppointmentService,
    private dateAdapter: DateAdapter<any>,
    public textService: TextService,
    public dataService: DataService,
    private route: ActivatedRoute,
    public apoointmentservice: AppointmentsService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.name = this.dataService.name;

    this.nameType = this.route.snapshot.paramMap.get("name");
    let periodTime = sessionStorage.getItem("appointment-filter");
    if (periodTime != "") {
      $(".date-input").prop("disabled", true);
    } else {
      $(".date-input").prop("disabled", false);
    }
    this.dataService.showGhostElements = true;
    this.showGhostElementsForTopPage = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }

    this.dateInput.from = moment(
      sessionStorage.getItem("appointment-from-date"),
      "DD/MM/YYYY"
    );
    this.dateInput.to = moment(
      sessionStorage.getItem("appointment-to-date"),
      "DD/MM/YYYY"
    );
    this.apoointmentservice
      .getAllAppointmentsByTypeByFilter(
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        periodTime,
        "",
        this.nameType,
        "",
        ""
      )
      .subscribe(
        (resp) => {
          this.dateInput.from = moment(
            sessionStorage.getItem("appointment-from-date"),
            "DD/MM/YYYY"
          );
          this.dateInput.to = moment(
            sessionStorage.getItem("appointment-to-date"),
            "DD/MM/YYYY"
          );
          $(".date-input").val(
            this.dateInput.to.format("DD/MM/YY") +
              " - " +
              this.dateInput.from.format("DD/MM/YY")
          );
          this.dataService.showDataLoader = false;
          this.dataService.showGhostElements = false;
          this.showGhostElementsForTopPage = false;
          this.total_count = this.dataService.getResourceText(
            this.textService.textSource["MEETINGS_SCREEN_SUBTITLE_1"],
            resp
          );
          this.total_held = this.dataService.getResourceText(
            this.textService.textSource["MEETINGS_SCREEN_SUBTITLE_2"],
            resp
          );
          this.total_scheduled = this.dataService.getResourceText(
            this.textService.textSource["MEETINGS_SCREEN_SUBTITLE_3"],
            resp
          );

          this.optionForStatusFilter = resp["status_filter"];
          this.optionsForPeriodFilter = resp["period_filter"];
          this.selectedStauts = this.optionForStatusFilter[0];
          this.optionsForPeriodFilter.forEach((element) => {
            if (element["value"] === periodTime) {
              this.selected_time_label = element;
            }
          });
          this.items = resp["meetings"];
          this.currentItemsToShow = this.items.slice(0, 10);

          this.paginatorLength = resp["pagination"]["total_count"];
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
  }

  resize(event: any) {
    this.pageWidth = event.target.innerWidth;
  }

  clear() {
    this.dateInput.from = "";
    this.dateInput.to = "";
    $(".date-input").val("");
    this.selectedStauts = this.optionForStatusFilter[0];
    this.selected_time_label = this.optionsForPeriodFilter[0];

    this.filterAppointments(0);
  }

  filterAppointments(startIndex) {
    if (this.selected_time_label["value"] != "") {
      $(".date-input").prop("disabled", true);
      this.dateInput.from = moment(
        this.selected_time_label["date_range"]["from"]
      );
      this.dateInput.to = moment(this.selected_time_label["date_range"]["to"]);
      $(".date-input").val(
        this.dateInput.to.format("DD/MM/YY") +
          " - " +
          this.dateInput.from.format("DD/MM/YY")
      );
    } else {
      $(".date-input").prop("disabled", false);
    }
    if (this.selectedStauts["value"] == null) {
      this.selectedStauts["value"] = "";
    }
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.dataService.showGhostElements = true;
    this.apoointmentservice
      .getAllAppointmentsByTypeByFilter(
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        this.selected_time_label["value"],
        this.selectedStauts["value"],
        this.nameType,
        10,
        startIndex
      )
      .subscribe(
        (resp) => {
          this.dataService.showGhostElements = false;
          this.dataService.showDataLoader = false;
          this.items = resp["meetings"];
          this.paginatorLength = resp["pagination"]["total_count"];
          this.currentItemsToShow = this.items.slice(0, 10);
          this.total_count = this.dataService.getResourceText(
            this.textService.textSource["MEETINGS_SCREEN_SUBTITLE_1"],
            resp
          );
          this.total_held = this.dataService.getResourceText(
            this.textService.textSource["MEETINGS_SCREEN_SUBTITLE_2"],
            resp
          );
          this.total_scheduled = this.dataService.getResourceText(
            this.textService.textSource["MEETINGS_SCREEN_SUBTITLE_3"],
            resp
          );
        },
        (err) => {
          this.dataService.showGhostElements = false;
          this.dataService.showDataLoader = false;
          this.dataService.handleErrors(err);
        }
      );
  }

  onPageChange($event) {
    let startIndex = $event.pageIndex * $event.pageSize;
    this.filterAppointments(startIndex);
    setTimeout(() => {
      $(".appointments-type-list").focus();
      window.scroll(0, 0);
    }, 100);
  }

  ngAfterContentInit(): void {
    this.datePicker = $(".date-input").daterangepicker(
      {
        autoApply: true,
        autoUpdateInput: false,
        opens: "center",
        locale: {
          format: "DD/MM/YYYY",
          separator: " - ",
          applyLabel: "אישור",
          cancelLabel: "ביטול",
          fromLabel: "מתאריך",
          toLabel: "עד תאריך",
          daysOfWeek: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
          monthNames: [
            "ינואר",
            "פברואר",
            "מרץ",
            "אפריל",
            "מאי",
            "יוני",
            "יולי",
            "אוגוסט",
            "ספטמבר",
            "אוקטובר",
            "נובמבר",
            "דצמבר",
          ],
          firstDay: 0,
        },
      },
      (start, end, label) => {
        this.dateInput.from = start;
        this.dateInput.to = end;
        $(".date-input").val(
          end.format("DD/MM/YY") + " - " + start.format("DD/MM/YY")
        );
        this.filterAppointments(0);
      }
    );
    $(".date-input").val("");
  }
}
