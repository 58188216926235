export enum MonthNamesEnum {
  JANUARY = "ינואר",
  FEBRUARY = "פברואר",
  MARCH = "מרץ",
  APRIL = "אפריל",
  MAY = "מאי",
  JUNE = "יוני",
  JULY = "יולי",
  AUGUST = "אוגוסט",
  SEPTEMBER = "ספטמבר",
  OCTOBER = "אוקטובר",
  NOVEMBER = "נובמבר",
  DECEMBER = "דצמבר",
}

export enum DayNamesEnum {
  SUNDAY = "ראשון",
  MONDAY = "שני",
  TUESDAY = "שלישי",
  WEDNESDAY = "רביעי",
  THURSDAY = "חמישי",
  FRIDAY = "שישי",
  SATURDAY = "שבת",
}

export enum LocalLabelEnum {
  APPLY = "אישור",
  CANCEL = "ביטול",
  FROM = "מתאריך",
  TO = "עד תאריך",
}

export const monthsOfTheYear = [
  MonthNamesEnum.JANUARY,
  MonthNamesEnum.FEBRUARY,
  MonthNamesEnum.MARCH,
  MonthNamesEnum.APRIL,
  MonthNamesEnum.MAY,
  MonthNamesEnum.JUNE,
  MonthNamesEnum.JULY,
  MonthNamesEnum.AUGUST,
  MonthNamesEnum.SEPTEMBER,
  MonthNamesEnum.OCTOBER,
  MonthNamesEnum.NOVEMBER,
  MonthNamesEnum.DECEMBER,
];

export const daysOfTheWeek = [
  DayNamesEnum.SUNDAY,
  DayNamesEnum.MONDAY,
  DayNamesEnum.TUESDAY,
  DayNamesEnum.WEDNESDAY,
  DayNamesEnum.THURSDAY,
  DayNamesEnum.FRIDAY,
  DayNamesEnum.SATURDAY,
];
