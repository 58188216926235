import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { ButtonComponent } from "../common/button/button.component";
import { ButtonDropdownComponent } from "../common/button-dropdown/button-dropdown.component";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { CommonModule } from "@angular/common";
import { UploadFileComponent } from "../modals/upload-file/upload-file.component";
import { ModalService } from "src/app/services/modal.service";
import { BreadcrumbsComponent } from "../common/breadcrumbs/breadcrumbs.component";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { UploadFilesComponent } from "../generic-components/upload-files/upload-files.component";
import { FileAttachmentService } from "src/app/services/file-attachment.service";
import { HeaderComponent } from "../common/header/header.component";
import { GeneralPopupComponent } from "../common/general-popup/general-popup.component";
import { PopupTypes } from "src/app/models/popuptype";
import { MatSelectModule } from "@angular/material/select";
import { FormBuilder, FormControl, ReactiveFormsModule } from "@angular/forms";
import { monthsOfTheYear } from "src/app/models/range-date-picker.model";
import { toSignal } from "@angular/core/rxjs-interop";
import { WorkTerminationPopupsComponent } from "../generic-components/work-termination-popups/work-termination-popups.component";

@Component({
  selector: "app-employer-action",
  templateUrl: "./employer-action.component.html",
  styleUrls: ["./employer-action.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ButtonDropdownComponent,
    UploadFileComponent,
    BreadcrumbsComponent,
    UploadFilesComponent,
    WorkTerminationPopupsComponent,
    HeaderComponent,
    GeneralPopupComponent,
    MatSelectModule,
    ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployerActionComponent implements OnInit {

  #fb = inject(FormBuilder);
  newAttachment = PopupTypes.newAttachment;
  modals = {};
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.EMPLOYER_ACTIONS,
  ];
  optionsForYear = [
    { name: "שנה נוכחית", value: new Date().getFullYear() },
    { name: "שנה שעברה", value: new Date().getFullYear() - 1 },
  ]
  selectDateGroup;
  optionsForMonth = monthsOfTheYear.map((month, index) => { return { name: month, value: index + 1 } });
  uploadedFiles = toSignal(this.fileAttachmentService.getFiles());
  public clickAddFile: boolean = false;
  public mainButton: string;
  constructor(
    public dataService: DataService,
    public textService: TextService,
    private modalService: ModalService,
    private readonly fileAttachmentService: FileAttachmentService
  ) {
  }
  ngOnInit(): void {
    if (!this.textService.textSource) {
      this.textService.setTextSource();
    }
    this.modalService.getModals().subscribe((modals) => {
      this.modals = modals;
    });
    //set default values of last month
    const lastMonthDate = new Date();
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
    this.selectDateGroup = this.#fb.group({
      selectedMonth: new FormControl<number>(lastMonthDate.getMonth() + 1), // 1-based month
      selectedYear: new FormControl<number>(lastMonthDate.getFullYear()),
    });

  }
  save() {
    this.dataService.showDataLoader = true;
    const selectedMonth = this.selectDateGroup.get('selectedMonth')?.value;
    const selectedYear = this.selectDateGroup.get('selectedYear')?.value;
    const date = new Date(selectedYear, selectedMonth - 1);
    date.setMonth(date.getMonth() + 1);
    const nextMonth = date.getMonth() + 1;
    const calculatedYear = date.getFullYear();
    const description = `${this.textService.textSource.COLLECTION_FILE_DESCRIPTION} ${nextMonth}/${calculatedYear}`;
    this.fileAttachmentService.sendFilesAttachment(undefined, description).subscribe(
      (resp) => {
        if (resp.success) {
          this.dataService.showNewAttachment = true;
          this.fileAttachmentService.clearFiles();
        }
        this.dataService.showDataLoader = false;
      },
      (err) => {

      }
    );
  }
}
