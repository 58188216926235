import {
  Component,
  OnInit,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { EmployeeService } from "src/app/services/employee.service";
import { TextService } from "../../../services/text.service";
import { Router } from "@angular/router";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { ModalService } from "src/app/services/modal.service";
import { HttpClient } from "@angular/common/http";
import { UploadFileComponent } from "../../modals/upload-file/upload-file.component";
import { RegularWorkerComponent } from "../regular-worker/regular-worker.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacyRadioModule } from "@angular/material/legacy-radio";
import { ButtonDropdownComponent } from "../../common/button-dropdown/button-dropdown.component";
import { HeaderComponent } from "../../common/header/header.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { NgIf } from "@angular/common";
import { WorkTerminationPopupsComponent } from "../../generic-components/work-termination-popups/work-termination-popups.component";
@Component({
  selector: "app-workers",
  templateUrl: "./workers.component.html",
  styleUrls: ["./workers.component.scss"],
  standalone: true,
  imports: [NgIf, BreadcrumbsComponent, HeaderComponent, ButtonDropdownComponent, MatLegacyRadioModule, ReactiveFormsModule, FormsModule, RegularWorkerComponent, UploadFileComponent, WorkTerminationPopupsComponent]
})
export class WorkersComponent implements OnInit, AfterViewChecked {
  name;
  regularWorker: boolean;
  showAllWorkers = true;
  total_count;
  total_actives;
  total_in_actives;
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.MY_EMPLOYEES,
  ];
  modals: {};

  constructor(
    public router: Router,
    private cdr: ChangeDetectorRef,
    public textService: TextService,
    public dataService: DataService,
    private employeeService: EmployeeService,
    public modalService: ModalService,
    private http: HttpClient
  ) {
    this.textService.setTextSource();
  }
  ngOnInit(): void {
    this.name = this.dataService.name;

    this.dataService.showGhostElements = true;
    this.employeeService.getEmployeesCounts().subscribe(
      (resp) => {
        this.total_count = this.dataService.getResourceText(
          this.textService.textSource["MY_EMPLOYEES_SCREEN_SUBTITLE_1"],
          resp
        );
        this.total_actives = this.dataService.getResourceText(
          this.textService.textSource["MY_EMPLOYEES_SCREEN_SUBTITLE_2"],
          resp
        );
        this.total_in_actives = this.dataService.getResourceText(
          this.textService.textSource["MY_EMPLOYEES_SCREEN_SUBTITLE_3"],
          resp
        );
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );

    this.modalService.getModals().subscribe((modals) => {
      this.modals = modals;
    });
  }
  ngAfterViewChecked(): void {
    if (!this.employeeService.employeeCardsGhostElements) {
      this.dataService.showGhostElements = false;
      this.cdr.detectChanges();
    }
  }

  goToRegister() {
    this.router.navigateByUrl("/register");
  }

  goToTask() {
    this.router.navigateByUrl("/tasks");
  }

  checkState(event, el) {
    event.preventDefault();
    if (this.regularWorker === el.value) {
      el.checked = false;
      this.regularWorker = null;
      this.showAllWorkers = true;
    } else {
      el.checked = true;
      this.regularWorker = el.value;
      this.showAllWorkers = false;
    }
  }
}
