import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralPopupComponent } from '../../common/general-popup/general-popup.component';
import { PopupTypes } from 'src/app/models/popuptype';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-work-termination-popups',
  standalone: true,
  imports: [CommonModule, GeneralPopupComponent],
  templateUrl: './work-termination-popups.component.html',
  styleUrls: ['./work-termination-popups.component.scss']
})
export class WorkTerminationPopupsComponent {
  dataService = inject(DataService);
  newAppointment = PopupTypes.newAppointment;
  aprovedRequest = PopupTypes.approvedRequest;
  isExistsProcedure = PopupTypes.isExistsProcedure;
}
