import { Location, NgClass, NgIf } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ITableData,
  TabValue,
} from "src/app/models/import-new-employees.model";
import { PopupTypes } from "src/app/models/popuptype";
import { IRegisterUserData } from "src/app/models/register-user-data.model";
import { DataService } from "src/app/services/data.service";
import { FileUploadService } from "src/app/services/file-upload.service";
import { ModalService } from "src/app/services/modal.service";
import { RegisterDataService } from "src/app/services/register-data.service";
import { RegisterPageService } from "src/app/services/register-page.service";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { LoaderComponent } from "../../common/loader/loader.component";
import { RegisteredImportedEmployeesSuccessComponent } from "../../modals/registered-imported-employees-success/registered-imported-employees-success.component";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { ToasterContainerComponent } from "../../common/toaster-container/toaster-container.component";
import { ButtonComponent } from "../../common/button/button.component";
import { ImportedEmployeesTableComponent } from "../../tables/imported-employees-table/imported-employees-table.component";
import { LogoComponent } from "../../common/logo/logo.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";

@Component({
  selector: "app-import-new-employees",
  templateUrl: "./import-new-employees.component.html",
  styleUrls: ["./import-new-employees.component.scss"],
  standalone: true,
  imports: [BreadcrumbsComponent, LogoComponent, NgClass, NgIf, ImportedEmployeesTableComponent, ButtonComponent, ToasterContainerComponent, GeneralPopupComponent, RegisteredImportedEmployeesSuccessComponent, LoaderComponent]
})
export class ImportNewEmployeesComponent implements OnInit, OnDestroy {
  tabValue = TabValue;
  activeTab: TabValue = this.tabValue.INCORRECT;
  tableData: ITableData;
  activeData: Array<{}>;
  error = PopupTypes.error;
  err_message: string;
  modals: {};
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.IMPORT_NEW_EMPLOYEES,
  ];

  constructor(
    public modalService: ModalService,
    private fileUploadService: FileUploadService,
    public dataService: DataService,
    private registerDataService: RegisterDataService,
    private registerPageService: RegisterPageService,
    private location: Location
  ) { }
  ngOnDestroy(): void {
    this.fileUploadService.setAllowUploadNewEmployeesPageAccess(false);
    this.fileUploadService.removeFile();
  }

  ngOnInit(): void {
    this.fileUploadService.getParsedFileResponse().subscribe((data) => {
      this.tableData = data;
      this.tableData.incorrect.length > 0
        ? this.clickTab(this.tabValue.INCORRECT)
        : this.clickTab(this.tabValue.CORRECT);
    });

    this.modalService.getModals().subscribe((modals) => {
      this.modals = modals;
    });
    // initialize form options for popup
    this.registerDataService.initFormOptions();
  }

  clickTab(chosenTab: TabValue) {
    this.activeTab = chosenTab;
    this.activeData =
      chosenTab === TabValue.CORRECT
        ? this.tableData.correct
        : this.tableData.incorrect;
  }

  async submit() {
    this.dataService.showDataLoader = true;
    const correct = this.tableData.correct;

    const employees = correct.map((employee) => {
      const registerUserBody = this.registerDataService.buildRegisterUserBody(
        employee as IRegisterUserData
      );
      return registerUserBody;
    });

    try {
      await this.registerPageService.createEmployees(employees).toPromise();
      // send file attachment
      await this.fileUploadService.sendFileAttachment();
      this.modalService.openModal("registeredSuccessfully");
      this.fileUploadService.removeFile();

    } catch (error) {
      await this.fileUploadService.sendFileAttachment();

      this.dataService.showErrorPopup = true;
    }
    finally {
      this.dataService.showDataLoader = false;
    }
  }

  goBack() {
    this.location.back();
  }
}
